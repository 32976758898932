.navbar {
    background-color: rgba(14, 51, 131, 1);
    color: rgba(248, 248, 248, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    user-select: none;
  }
  
  .logo-container img {
    width: 300px;
    height: 80px;
  }
  
  .title {
    font-size: 32px;
    font-weight: semi-bold;
    color: rgba(248, 248, 248, 1);
    margin-left: auto;
  }
  