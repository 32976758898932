*:focus {
  outline: none;
}

.info-adicionais-container {
  margin-top: 16px;
  margin-bottom: 16px;    
  border-bottom: 1px solid #d3d3d3;
}

.info-adicionais-card {
  background-color: #ffffff;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;    
}

.info-adicionais-coluna1,
.info-adicionais-coluna2,
.info-adicionais-coluna3,
.info-adicionais-coluna4 {
  flex: 1;
  margin: 16px;
  margin-right: 40px;
  text-align: left;
  position: relative; /* Required for the pseudo-element */
}

.info-adicionais-coluna1 .logo::after,
.texto-h5-instituicao::after,
.texto-h5-matriz::after,
.texto-h5-apps::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #606A80; /* Color of the line */
  margin-top: 8px; /* Adjust for spacing */
}

.logo {       
  width: 180px;
  height: 32px;
  margin-bottom: 32px;
  position: relative;
}

.texto-h5-institucional {
  font-size: 20px;
  color: #606A80;
  text-align: left;
  font-weight: 400;
}

.texto-h5-copyright {
  font-size: 16px;
  color: #606A80;
  text-align: left;
  margin-top: 80px;
}

.texto-h5-instituicao,
.texto-h5-matriz,
.texto-h5-apps {
  font-size: 20px;
  font-weight: 700;
  color: #0E3383;
  text-align: left;
  margin-bottom: 8px;
}

.texto-h5-instituicao {
  margin-bottom: 32px;
}

.texto-h5-matriz {
  margin-bottom: 32px;
}

.texto-central-atendimento {
  font-size: 20px;
  color: #606A80;
  text-align: left;
  font-weight: 500;
  margin-bottom: 24px;
}

.botao {
  display: block;    
  font-size: 20px;
  font-weight: 500;
  margin-top: 12px;
  background-color: #fff;
  color: #0E3383;
  border: none;
  cursor: pointer;
  text-align: left;
}

.botao:hover {
  color: #4169c0; /* Cor do texto ao passar o mouse */
  background-color: transparent; /* Cor do texto ao passar o mouse */
}

.texto-h5-endereco,
.texto-h5-cidade,
.texto-h5-apps {
  font-size: 20px;
  color: #606A80;
  text-align: left;
  font-weight: 500;
}

.imagem-app {
  display: block;
  margin-top: 8px;
  width: 50%;
  height: auto;
  cursor: pointer;
}
