*:focus {
  outline: none;
}

.video-section {
  text-align: center;
  margin-top: 64px;
}

.video-title-h1 {
  font-size: 32px;
  color: #0E3383;
  margin-bottom: 48px;  
}
.video-title-h2 {
  font-size: 24px;
  color: #606A80;
  margin-bottom: 24px;  
}

.video-institucional-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-institucional {
  width: 100%;
}

@media (max-width: 1200px) {
  .video-title-h1 {
    font-size: 32px;
  }

  .video-title-h2 {
    font-size: 24px;
  }
}

@media (max-width: 710px) {
  .video-title-h1 {
    font-size: 24px;
  }
  .video-title-h2 {
    font-size: 24px;
  }
}

@media (max-width: 580px) {
  .video-title-h1 {
    font-size: 16px;
  }
  .video-title-h2 {
    font-size: 16px;
  }
}
