*:focus {
    outline: none;
  }

.redes-sociais-container {    
    margin-bottom: 80px;
    margin-left: 20px ;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 80px;
}

.icones {
    display: flex;    
}
