*:focus {
  outline: none;
}

.principal {
  background-color: #faf6f6;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  margin: 24px auto;
  padding: 48px;
  max-width: 1000px;  
}

.left-aligned {
  display: flex;
  justify-content: flex-start;
}

.left-aligned-card {
  width: 100%;
  margin-left: 0;
}

.contact-text {
  color: #0e3383;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 40px;
  font-style: normal;
  margin: 48px;
}

.imagem-manual {
  width: 100%;
  height: auto;
  margin-top: 16px;
}

.texto-h5 {
  color: #333333;
  font-family: "Helvetica", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 24px;
  font-style: normal;
  text-align: left;
}

.texto-h6 {
  color: #333333;
  font-family: "Helvetica", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  text-align: left;
}

.top-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;
}

.bottom-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 24px;
}

/* ******Responsividade 1000px****** */
@media (max-width: 1000px) {
  .contact-text {
    font-size: 32px;    
  }
}

/* ******Responsividade 800px****** */
@media (max-width: 800px) {
  .contact-text {
    font-size: 24px;    
  }
}
