*:focus {
  outline: none;
}

.search-bar {
    display: flex;
    align-items: center;
    border: 2px solid #ddd;
    padding: 0px;
    max-width: 600px;
    margin: 1px auto;
    border-radius: 22px;
  }
  
  input {
    flex: 1;
    border: 0px;
    outline: none;
    padding: 8px;
    padding-left: 16px;
    font-size: 16px;
    border-radius: 20px 0 0 20px;
    transition: border-color 0.3s;
    user-select: none;
  }
  
  input:focus {
    border-color: #00bcd4;
  }
  
  .button-pesquisar {
    border: 0px solid #ddd;
    outline: none;
    padding: 10px;
    width: 64px;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #ddd; /* Altera a cor de fundo do botão quando o mouse passa sobre ele */
  }
  