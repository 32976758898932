.whatsapp {
    position: fixed;
    bottom: 15px; 
    left: 180px; 
    z-index: 1000; 
    background-color: rgb(255, 255, 255);
    color: hsl(0, 0%, 100%);
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.12);
    background: linear-gradient(45deg, #67bbff4b 15%, #edf3ff 98%);
}  
