*:focus {
  outline: none;
}

.topo-principal {
  background-color: #F7F8FA;
  padding: 104px;
  text-align: center;
}

.titulo-inicial {
  background-color: #F7F8FA;  
  text-align: center;  
}

.titulo-inicial-text {  
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 48px;
  font-style: normal;
}

.titulo-inicial-text .highlight {
  color: #0E3383;
}

.texto-botao-imagem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 96px;
  padding-left: 144px;
  padding-right: 144px;
}

.texto-botao-container {
  display: flex;
  flex-direction: column;
  margin-right: 80px;
  margin-bottom: 48px;  
}

.texto-esquerdo-text {
  order: 1;
  color: #5A5B5B;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 24px;
  padding-bottom: 120px;  
}

.botao-conheca {    
  text-align: left;
}

.btn-conhecagratis {
  order: 2;
  max-width: 400px;
  background-color: #AE8800;  
  color: white;
  border: none;
  padding: 12px 80px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 32px;
  transition: background-color 0.3s ease;
}

.btn-conhecagratis:hover {
  background-color: #caa009;
}

.imagem-direita {  
  order: 3;
  flex: auto;  
}

.top-image {  
  height: auto;
  padding-left: 120px;
}

.texto-abaixo {
padding: 0px;
}

.texto-abaixo-text {
  color: #333333;
  text-align: center;
  margin-top: 80px;
  margin-left: 0px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 24px;
}

.texto-abaixo .highlight {
  color: #0E3383;
}



/* ******Responsividade 1600px****** */
@media (max-width: 1600px) {
  .topo-principal {
    background-color: #F7F8FA;
    padding: 64px;
    text-align: center;
  }
  
  .titulo-inicial {
    background-color: #F7F8FA;  
    text-align: center;  
  }
  
  .titulo-inicial-text {  
    color: #333333;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 40px;
    font-style: normal;
  }
  
  .texto-botao-imagem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .texto-botao-container {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    margin-bottom: 24px;
    max-width: 400px;
  }
  
  .texto-esquerdo-text {
    order: 1;
    color: #5A5B5B;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 48px;  
  }
  
  .botao-conheca {    
    text-align: left;
  }
  
  .btn-conhecagratis {
    order: 2;
    max-width: 320px;
    background-color: #AE8800;  
    color: white;
    border: none;
    padding: 8px 32px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 20px;
    margin-top: 32px;
    transition: background-color 0.3s ease;
  }
  
  .btn-conhecagratis:hover {
    background-color: #ffc800;
  }
  
  .imagem-direita {
    order: 3;
    flex: auto;  
  }
  
  .top-image {  
    height: auto;
    width: 100%;
    height: auto;
    padding-left: 24px;
    padding-right: 16px;

  }
  
  .texto-abaixo {
  padding: 24px;
  }
  
  .texto-abaixo-text {
    color: #333333;
    text-align: center;
    margin-top: 80px;
    margin-left: 0px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 32px;
  }
  
  .texto-abaixo .highlight {
    color: #0E3383;
  }
}




/* ******Responsividade 1000px****** */
@media (max-width: 1000px) {
  .topo-principal {    
    padding-top: 64px;
    padding-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
  }

  .titulo-inicial {    
    text-align: center;
    padding: 16px;
  }

  .titulo-inicial-text {
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 32px;
    font-style: normal;    
  }

  .texto-botao-imagem {
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding-bottom: 48px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 32px;
  }

  .texto-botao-container {    
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    flex-direction: column;
    align-items: center;
  }
  
  .imagem-direita {
    order: 3;
    margin-top: 64px;    
  }

  .top-image {    
    height: auto;
    width: 80%;  
    height: auto;
    padding-left: 0px;
  }

  .texto-esquerdo-text {
    order: 1;
    color: #5A5B5B;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 16px;
  }

  .btn-conhecagratis {
    order: 2;
    max-width: 320px;
    background-color: #AE8800;  
    color: white;
    border: none;
    padding: 8px 80px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 16px;
    transition: background-color 0.3s ease;
  }
  
  .texto-abaixo {
    padding: 16px;
    }

  .texto-abaixo-text {
    color: #333333;
    text-align: center;
    margin-top: 8px;
    font-size: 20px;
  }
}




/* ******Responsividade 600px****** */
@media (max-width: 600px) {
  .topo-principal {    
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
  }

  .titulo-inicial {    
    text-align: center;
    padding: 16px;
  }

  .titulo-inicial-text {
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 20px;
    font-style: normal;    
  }

  .texto-botao-imagem {
    display: flex;
    flex-direction: column;
    align-items: center;    
    padding-bottom: 48px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 16px;
  }

  .texto-botao-container {    
    margin-right: 0px;
    margin-bottom: 0px;
    padding-right: 0px;
    flex-direction: column;
    align-items: center;
  }
  
  .imagem-direita {
    order: 3;
    margin-top: 48px;    
  }

  .top-image {    
    height: auto;
    width: 80%;  
    height: auto;
    padding-left: 0px;
  }

  .texto-esquerdo-text {
    order: 1;
    color: #5A5B5B;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 16px;
  }

  .btn-conhecagratis {
    order: 2;
    max-width: 320px;
    background-color: #AE8800;  
    color: white;
    border: none;
    padding: 8px 64px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 16px;
    transition: background-color 0.3s ease;
  }
  
  .texto-abaixo {
    padding: 16px;
    }

  .texto-abaixo-text {
    color: #333333;
    text-align: center;
    margin-top: 8px;
    font-size: 20px;
  }
}