*:focus {
  outline: none;
}

.container {
}

.etiquetas-titulo-container {
  text-align: center;
  margin: 80px 0;  
}

.etiquetas-titulo {
  color: #0E3383;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 48px;
  font-style: normal;
}

.etiqueta-section-titulo {
  font-size: 1.8em;
  margin: 40px 0 20px;
  text-align: left;
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.etiquetas-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.etiqueta-card {
  background-color: #F7F8FA;
  border: 1px solid #e9ebf0;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.etiqueta-modelo {
  font-size: 1.4em;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 700;
}

.etiqueta-conteudo {
  display: flex;
  align-items: center;
}

.etiqueta-imagem {
  max-width: 100%;
  margin-right: 32px;
}

.etiqueta-textos {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.etiqueta-texto {
  font-size: 1.0em;
  margin: 6px 0;
}

/* Media query para telas menores que 1200px */
@media (max-width: 1200px) {
    .etiqueta-section-titulo {
        font-size: 1.5em;
    }
    
    .etiqueta-conteudo {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .etiqueta-imagem {
      margin: 0 0 20px;
      width: 60%;      
    }
  
    .etiqueta-modelo {
      text-align: left;
      width: 100%;
      font-size: 1.4em;
      font-weight: 600;
    }
  
    .etiqueta-textos {
      width: 100%;
      text-align: left;
    }
  }



  @media (max-width: 770px) {
    .etiqueta-section-titulo {
        font-size: 1.5em;
    }
    
    .etiqueta-conteudo {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .etiqueta-imagem {
      margin: 0 0 20px;
      width: 100%;      
    }
  
    .etiqueta-modelo {
      text-align: left;
      width: 100%;
      font-size: 1.4em;
      font-weight: 600;
    }
  
    .etiqueta-textos {
      width: 100%;
      text-align: left;
    }
  }


  @media (max-width: 430px) {
    .etiqueta-section-titulo {
        font-size: 1.2em;
    }
    
    .etiqueta-conteudo {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .etiqueta-imagem {
      margin: 0 0 20px;
      width: 100%;      
    }
  
    .etiqueta-modelo {
      text-align: left;
      width: 100%;
      font-size: 1.4em;
      font-weight: 600;
    }
  
    .etiqueta-textos {
      width: 100%;
      text-align: left;
    }
  }