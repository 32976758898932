*:focus {
  outline: none;
}

/* Adicione estilos à barra de navegação de suporte */
.navbar-suporte {
    background-color: #e6e6ee; /* Cor de fundo da barra de navegação */
    padding: 16px;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 100; /* Certifique-se de que o z-index seja alto o suficiente */
  }
  
  /* Estilos para o conteúdo dentro da barra de navegação */
  .suporte {
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding-top: 16px ;
    padding-bottom: 16px ;
    font-size: 24px;
  }
  
  .logo-suporte img {
    height: 64px; /* Altura da logo */
    margin-left: 300px;
  }
  
  .contato-suporte {
    text-align: right;    
    margin-right: 300px;
    color: #303030;
  }
  