*:focus {
  outline: none;
}

.titulo {
  font-size: 32px;
  font-weight: semi-bold;
  margin-left: auto;
  margin-top: 64px;
  margin-bottom: 64px;
  user-select: none;
}

.button-container{
  background-color: #F8F8F8;  
}

.button-novidades {  
  display: inline-block;
  width: 200px; /* Defina a largura desejada para todos os botões */
  margin-right: 10px; /* Ajuste a margem conforme necessário para o espaçamento entre os botões */
  border: 2px solid #086F82; /* Cor e largura da borda */
  border-radius: 16px; /* Borda arredondada */
  text-align: center;    
  padding: 32px;
  user-select: none;  
  cursor: pointer;
}

.button-novidades span {
  display: block;  
}

.button-novidades svg {
  margin-bottom: 16px ;
  font-size: 48px; /* Ajuste o tamanho do ícone conforme necessário */
  color: #086F82;
}

.buttonsegundo-container{
  background-color: #F8F8F8;  
  padding-top: 8px;
}

.categoria{
  margin-top: 64px;
  padding-top: 16px;
  background-color: #F8F8F8;
  margin-bottom: 48px;  
  user-select: none;
}

.botoes-categoria{
  background-color: #F8F8F8;
  padding-bottom: 64px;
}

.button-novidades .button-text {
  color: #086F82; /* Ajuste a cor conforme necessário */
}