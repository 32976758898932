*:focus {
  outline: none;
}

.container {
  padding: 32px;
}  

.politica-privacidade {
  font-size: 12;
  color: black;
  margin-top: 5%;
  padding-left: 20px;
  text-align: justify;
}  