*:focus {
    outline: none;
  }

  
.segmento-container {
    background-color: #ffffff;
    text-align: center;
    margin: 104px;
    padding-left: 180px;
    padding-right: 180px;
    align-items: center;
}

.segmento-container h1 {
    margin: 80px;
    font-size: 32px;
    color: #333333;
}

.segmento-divider {
    border-top: 1px solid #ddd;
    margin: 20px 0;
    padding-top: 20px;
}

.segmento-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.segmento-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

.segmento-description {
    text-align: center; /* Alinha o texto de explicação ao centro */
    margin: 10px 0;
}

.segmento-button {
    margin-top: 10px; /* Ajusta o espaçamento entre o texto e o botão */
}
