*:focus {
    outline: none;
  }

.buttonfuncionalidades-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white; /* Fundo branco */
    padding: 48px; /* Espaçamento interno */
    margin-bottom: 48px;
}

.title-section {
    margin: 48px;
}

.title-section h1 {
    margin: 48px 0;
    color: #0E3383;
}

.title-section h4 {
    margin: 48px;
    color: #606A80;
    font-size: 24px;
}

.buttons-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 colunas com largura igual */
    gap: 32px;
    justify-items: center; /* Centraliza os itens horizontalmente */
}

.custom-button {
    background-color: #E3EAFF; /* Mude para a cor que preferir */
    border: 0;
    border-color: #0E3383;
    color: #0E3383;
    padding: 12px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
    width: 100%; /* Botões ocupam toda a largura do grid */
    text-transform: uppercase; /* Transforma o texto do botão em maiúsculas */
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
}

.custom-button:hover {
    background-color: rgb(72, 107, 180); /* Mude para a cor que preferir */
    color: white; /* Mude para a cor que preferir */
}

@media (max-width: 1210px){

    .custom-button {   
        font-size: 12px;     
        padding: 12px 12px;
        width: 280px;
        height: 56px;
    }
}

@media (max-width: 980px){

    .custom-button {        
        font-size: 12px;
        padding: 12px 12px;
        width: 200px;
        height: 56px;
    }
}

@media (max-width: 710px){

    .title-section {
        margin: 0px;
        margin-bottom: 32px;
    }
    
    .title-section h1 {
        margin: 48px 0;
        color: #0E3383;
        font-size: 24px;
    }
    
    .title-section h4 {
        margin: 24px;
        color: #606A80;
        font-size: 16px;
    }

    .buttons-section {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        justify-items: center; /* Centraliza os itens horizontalmente */
    }

    .custom-button {        
        font-size: 12px;
        padding: 12px 12px;
        width: 240px;
        height: 56px;
    }
}

@media (max-width: 550px){

    .title-section {
        margin: 0px;
        margin-bottom: 32px;
    }
    
    .title-section h1 {
        margin: 48px 0;
        color: #0E3383;
        font-size: 24px;
    }
    
    .title-section h4 {
        margin: 24px;
        color: #606A80;
        font-size: 16px;
    }

    .buttons-section {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        justify-items: center; /* Centraliza os itens horizontalmente */
    }

    .custom-button {        
        font-size: 12px;
        padding: 12px 12px;
        width: 280px;
        height: 40px;
    }
}