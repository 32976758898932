*:focus {
  outline: none;
}

.toolbar {
    position: fixed; 
    top: 0;
    left: 0; 
    width: 100%;   
    max-height: 100px;
    z-index: 100;  
    background-color: rgb(255, 255, 255);
    color: hsl(0, 0%, 100%);
    padding: 10px; 
    box-sizing: border-box;     
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 6px 20px rgba(0, 0, 0, 0.12);
    background: linear-gradient(45deg, #dfe7eecc 15%, #edf3ff 98%);       
  }

.card-socialmedia{
    display: flex;
    align-items: left;
    margin: 10px;
    margin-right: 400px;
  }

  