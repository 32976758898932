*:focus {
  outline: none;
}

.rootDrawer {
  display: flex;    
}

.appBar {
  transition: margin 0.3s ease, width 0.3s ease;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
}

.appBarShift {
  width: calc(100% - 240px);
  transition: margin 0.3s ease, width 0.3s ease;
}

.title {
  flex-grow: 1;
}

.hide {
  display: none;
}

.drawer {
  width: 240px;
  flex-shrink: 0;
}

.drawerPaper {
  width: 240px;
}

.drawerHeader {
  display: flex;
  align-items: center;
  padding: 0 8px;
  min-height: 64px;
  box-sizing: border-box;
}

.content {
  flex-grow: 1;
  padding: 24px;
  transition: margin 0.3s ease;  
}

.contentShift {
  margin-right: 240px;
}

.drawerItemText {
  color: #333333;
}

.drawerItemText:hover {
  color: #FFD700;
}

.faleConosco {
  color: #5F85D7 !important;
}

.faleConosco:hover {
  color: #FFD700 !important;
}
