*:focus {
  outline: none;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f8fa;
}

.cards-section {
  width: 100%;
  max-width: 1600px;
  padding: 0 48px;
  margin-top: 48px;
}

.card {
  display: flex;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 64px 0;
  padding: 48px;
  max-height: 560px;
  overflow: hidden;
}

.card.odd {
  flex-direction: row;
}

.card.even {
  flex-direction: row-reverse;
}

.card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left; /* Alinha ao centro horizontalmente */
}

.card-image-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image {
  max-width: 80%;
  height: auto;
}

.card-h1 {
  font-size: 32px;
  font-weight: 600;
  color: #0e3383;
  margin-bottom: 24px;
  text-align: left;
}

.card-h4 {
  font-size: 24px;
  color: #6c757d;
  margin-bottom: 24px;
  text-align: left;
}

.card-h5 {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 8px;
  text-align: left;
}

.button-container {
  display: flex;
  margin-top: 32px;
  width: 100%; /* Ajusta a largura para o container do botão */
  display: flex;
  justify-content: center; /* Centraliza o botão horizontalmente */

}

@media (max-width: 1200px) {
  .cards-section {
    width: 100%;
    max-width: 1600px;
    padding: 0 24px;
    margin-top: 48px;
  }

  .card {
    display: flex;
    background-color: #ffffff;  
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 64px 0;
    padding: 48px;
    max-height: 560px;
    overflow: hidden;
  }
  
  .card.odd {
    flex-direction: row;
  }
  
  .card.even {
    flex-direction: row-reverse;
  }

  .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .card-image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-image {
    max-width: 80%;
    height: auto;
  }
  
  .card-h1 {
    font-size: 32px;
    font-weight: 600;
    color: #0e3383;
    margin-bottom: 24px;
    text-align: left;
  }
  
  .card-h4 {
    font-size: 20px;
    font-weight: 500;
    color: #606a80;
    text-align: left;
    margin-bottom: 32px;
  }
  
  .card-h5 {
    font-size: 16px;
    font-weight: 400;
    color: #606a80;
    margin-bottom: 12px;
    margin-left: 16px;
    text-align: left;
  }
}


@media (max-width: 980px) {
  .cards-section {
    width: 100%;
    max-width: 1600px;
    padding: 0px;
    margin-top: 48px;
  }

  .card {
    display: flex;
    background-color: #ffffff;  
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);    
    margin: 16px 0;
    padding: 24px;
    max-height: 800px;
    overflow: hidden;
    flex-direction: column; /* Altera a direção do flex para coluna */
    align-items: center; /* Centraliza os itens */
    padding: 24px; /* Ajusta o padding */
  }
  
  .card.odd, .card.even {
    flex-direction: column; /* Remove as direções específicas de odd e even */
  }

  .card-image-wrapper {
    flex: none;
    width: 60%; /* Ajusta a largura para ocupar 100% */
    margin-bottom: 24px; /* Adiciona espaço abaixo da imagem */
  }

  .card-image {
    max-width: 100%;
    height: auto;
  }

  .card-content {
    align-items: left; /* Centraliza o conteúdo */
    text-align: left; /* Centraliza o texto */
    flex: none;
    width: 100%; /* Ajusta a largura para ocupar 100% */
  }

  .card-h1 {
    font-size: 24px;
    font-weight: 600;
    color: #0e3383;
    margin-bottom: 24px;
    text-align: left;
  }

  .card-h4 {
    font-size: 16px;
    font-weight: 500;
    color: #606a80;
    text-align: left;
    margin-bottom: 32px;
  }

  .card-h5 {
    font-size: 16px;
    font-weight: 400;
    color: #606a80;
    margin-bottom: 12px;
    margin-left: 0px;
    text-align: left;
  }
}


@media (max-width: 580px) {
  .cards-section {
    width: 100%;
    max-width: 1600px;
    padding: 0px;
    margin-top: 48px;
  }

  .card {
    display: flex;
    background-color: #ffffff;  
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);    
    margin: 16px 0;
    padding: 24px;
    max-height: 1000px;
    overflow: hidden;
    flex-direction: column; /* Altera a direção do flex para coluna */
    align-items: center; /* Centraliza os itens */
    padding: 24px; /* Ajusta o padding */
  }
  
  .card.odd, .card.even {
    flex-direction: column; /* Remove as direções específicas de odd e even */
  }

  .card-image-wrapper {
    flex: none;
    width: 100%; /* Ajusta a largura para ocupar 100% */
    margin-bottom: 24px; /* Adiciona espaço abaixo da imagem */
  }

  .card-image {
    max-width: 100%;
    height: auto;
  }

  .card-content {
    align-items: left; /* Centraliza o conteúdo */
    text-align: left; /* Centraliza o texto */
    flex: none;
    width: 100%; /* Ajusta a largura para ocupar 100% */
  }

  .card-h1 {
    font-size: 24px;
    font-weight: 600;
    color: #0e3383;
    margin-bottom: 24px;
    text-align: left;
  }

  .card-h4 {
    font-size: 16px;
    font-weight: 500;
    color: #606a80;
    text-align: left;
    margin-bottom: 32px;
  }

  .card-h5 {
    font-size: 16px;
    font-weight: 400;
    color: #606a80;
    margin-bottom: 12px;
    margin-left: 0px;
    text-align: left;
  }
}
