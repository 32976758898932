*:focus {
  outline: none;
}

.toolbar {
  display: flex;
  background-color: #ffffff; /* Cor de fundo branca */
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 88px; /* Ajuste conforme necessário */
  z-index: 10; /* Adiciona z-index para garantir que esteja na frente */
}

.toolbar .left-section {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.toolbar .center-section {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin-right: 32px; /* Adicionando margem direita para afastar da seção direita */
}

.toolbar .right-section {
  display: flex;
  align-items: center;
  margin-right: 24px;
  z-index: 10; /* Adiciona z-index para garantir que esteja na frente */
}

.toolbar img {
  cursor: pointer;  
  margin-right: 16px;
  max-width: 264px;
  max-height: 64px;
}

.toolbar h4 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: #333333;
  margin: 0 16px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s; /* Adiciona transição suave */
}

.toolbar h4.fale-conosco {
  color: #4f76cc; /* Cor específica para Fale Conosco */
}

.toolbar h4:hover {
  transform: scale(1.1); /* Aumenta ligeiramente o ícone */
  border-radius: 50%; /* Faz o fundo ficar arredondado */
}

.toolbar .social-icons {
  display: flex;
  align-items: center;
  gap: 24px; /* espaço entre os ícones */
  margin-left: 32px;
  z-index: 10; /* Adiciona z-index para garantir que esteja na frente */
}

.toolbar .social-icons .icones {
  transition: transform 0.3s, background-color 0.3s; /* Adiciona transição suave */
}

.toolbar .social-icons .icones:hover {
  transform: scale(1.1); /* Aumenta ligeiramente o ícone */
  border-radius: 50%; /* Faz o fundo ficar arredondado */
}

.bottom-toolbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column; /* Adiciona direção de coluna */
  z-index: 9999; /* Garantir que a toolbar-bottom fique na frente */
  background-color: transparent; /* Cor de fundo */
}

.top-row {
  display: flex;
  justify-content: flex-end; /* Alinha à direita */  
  background-color: transparent;
  padding-right: 16px;
}

.bottom-row {
  display: flex;
  justify-content: space-between; /* Distribui os itens uniformemente */  
  background-color: #ffffff;
}

.left-bottom, .right-bottom {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.left-bottom {
  background-color: #3b5eaa;  
  justify-content: right; /* Centraliza o conteúdo */
  padding-right: 24px; /* Remover essa linha se não precisar de padding à direita */  
}

.right-bottom {
  background-color: #0E3383;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distribui os itens uniformemente */
  padding-left: 24px; /* Adiciona espaçamento da extremidade esquerda */
  padding-right: 24px; /* Adiciona espaçamento da extremidade direita */
}

.bottom-button {
  background: none;
  border: none;
  color: #fff;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-button:hover {
  transform: scale(1.1); /* Aumenta ligeiramente o ícone */
  border-radius: 50%; /* Faz o fundo ficar arredondado */
  background-color: transparent; /* Cor do texto ao passar o mouse */
}


.whatsapp-button {
  display: flex;
  align-items: center;
  gap: 8px; /* Espaçamento entre o ícone e o texto */
  padding: 8px 16px; /* Adiciona padding */
  border-radius: 4px; /* Cantos arredondados */
  background-color: #0E3383; /* Cor de fundo específica */
}

.whatsapp-icon {
  color: #fff; /* Cor do ícone */
}

.support-button {
  background-color: #3b5eaa;  
  justify-content: right; /* Centraliza o conteúdo */
  padding-right: 24px; /* Remover essa linha se não precisar de padding à direita */  
  border: 0px;
  color: white;
  padding-left: 32px; 
  padding-right: 32px; 
  padding-bottom: 8px;
  padding-top: 4px;
  margin-left: 16px;
  border-top-left-radius: 8px;  
  border-top-right-radius: 8px;  
}

.support-button:hover {
  background-color: #3b5eaa;  
  transform: scale(1.1);
}

@media (max-width: 1200px) {
  .support-button {
    display: none;
  }

  .toolbar .right-section{
    display: none;
  }
}

