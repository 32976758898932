*:focus {
  outline: none;
}

.contact-section {
    background-color: #F7F8FA;
    padding: 104px;
    text-align: center;
  }
  
  .contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .contact-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-text {
    margin-left: 20px;
    text-align: left;
  }
  
  .contact-text h1 {
    font-size: 24px;
    color: #0E3383;
    font-weight: bold;
  }
  
  .contact-text h4 {
    font-size: 20px;
    color: #606A80;
    margin-top: 10px;
  }
  
  .central-text {
    margin-bottom: 48px;
    margin-top: 48px;
    font-size: 28px;
    font-weight: 600;
    color: #0E3383;    
  }
  
  .contact-buttons {
    display: flex;
    justify-content: center;
    gap: 24px;
  }
  
  .contact-button {
    width: 260px;
    height: 150px;
    background-color: #FFFFFF;
    border: 0px solid #606A80;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    text-decoration: none; /* Remove underline from links */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  }
  
  .contact-button:hover {
    background-color: rgb(231, 239, 255);
    color: white;
  }
  
  .button-icon {
    color: #0E3383; /* Change the color of the FontAwesome icon */
    margin-bottom: 16px;
  }
  
  .button-text p {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #2f333d;
  }



  
  /* ******Responsividade 1000px****** */
  @media (max-width: 1000px) {
    .contact-section {
      background-color: #F7F8FA;
      padding: 64px;
      text-align: center;
    }
  
    .contact-text h1 {
      font-size: 20px;
      color: #0E3383;
      font-weight: bold;
    }
    
    .contact-text h4 {
      font-size: 16px;
      color: #606A80;
      margin-top: 10px;
    }
  }



  
  /* ******Responsividade 700px****** */
  @media (max-width: 700px) {
    .contact-content {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-text {
      margin-left: 0;
      text-align: center;
    }
  
    .contact-text h1 {
      font-size: 20px;
      color: #0E3383;
      font-weight: bold;
    }
    
    .contact-text h4 {
      font-size: 16px;
      color: #606A80;
      margin-top: 10px;
    }
    
    .contact-image {
      margin-bottom: 20px;
    }

    
  .central-text {
    margin-bottom: 48px;
    margin-top: 48px;
    font-size: 20px;
    font-weight: 600;
    color: #0E3383;    
  }

    .contact-button {
        width: 200px;
        height: 120px;
        background-color: #FFFFFF;
        border: 0px solid #606A80;
        box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        text-decoration: none; /* Remove underline from links */
        transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
      }

    .button-icon {
        color: #0E3383; /* Change the color of the FontAwesome icon */
        margin-bottom: 12px;
      }

    .button-text p {
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #2f333d;
  }
}
  