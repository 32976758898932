*:focus {
  outline: none;
}

.contact-section {
  background-color: #F7F8FA;
  padding: 40px;
  text-align: center;
}

.central-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  margin-top: 48px;
  font-size: 32px;
  font-weight: 600;
  color: #0E3383;
}

.phone-icon {
  margin-right: 16px;
  color: #0E3383; /* Cor do ícone de telefone */
}

.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.contact-button {
  width: 260px;
  height: 150px;
  background-color: #FFFFFF;
  border: 0px solid #606A80;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-decoration: none; /* Remove underline from links */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.contact-button:hover {
  background-color: rgb(231, 239, 255);
  color: white;
}

.button-icon {
  color: #0E3383; /* Change the color of the FontAwesome icon */
  margin-bottom: 16px;
}

.button-text p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #2f333d;
}

/* ******Responsividade 1000px****** */
@media (max-width: 1000px) {
  .contact-section {
    padding: 64px;
  }
  
  .central-text {
    font-size: 24px;
  }
}

/* ******Responsividade 700px****** */
@media (max-width: 700px) {
  .central-text {
    font-size: 20px;
  }

  .contact-button {
    width: 200px;
    height: 120px;
  }

  .button-icon {
    margin-bottom: 12px;
  }

  .button-text p {
    font-size: 16px;
  }
}
